import React, { useContext } from "react"
import { Bar } from "react-chartjs-2"
import { ThemeContext } from "styled-components"

const BarChart = ({ data, yAxisStepSize }) => {
  const themeContext = useContext(ThemeContext)
  const maxValue = Math.max(...data.map(d => d.yValue))
  return (
    <Bar
      options={{
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: themeContext.color,
                fontSize: 18,
                fontFamily: themeContext.fontFamilyAlt,
                padding: 20,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                tickMarkLength: 0,
              },
              ticks: {
                startAtZero: true,
                min: 0,
                stepSize:
                  yAxisStepSize ||
                  (maxValue > 1000 ? 500 : maxValue > 200 ? 50 : 25),
                display: true,
              },
            },
          ],
        },
      }}
      data={{
        labels: data.map((d, i) =>
          i === 0 || i === data.length - 1 ? d.xValue : ""
        ),
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: themeContext.primaryColor,
            borderWidth: 0,
            data: data.map(d => d.yValue),
          },
        ],
      }}
    />
  )
}

export default BarChart
