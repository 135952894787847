import React, { useState } from "react"
import { Span, SubTitle } from "../../components/styles/TextStyles.styled"
import * as S from "./ChartSet.styled"
import BarChart from "../../components/BarChart"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import GridLayout from "../../layouts/GridLayout"
import DatoCmsText from "../../components/DatoCmsText/DatoCmsText"

const ChartSetSection = ({ title, cta, charts, ...props }) => {
  const [page, setPage] = useState(0)
  return (
    <GridLayout noMarginRight {...props}>
      <LinkedSectionTitle title={title} cta={cta} transparentLinkBackground />
      <S.ChartDescriptionSection>
        <S.ChartDescriptionTitle>{charts[page].title}</S.ChartDescriptionTitle>
        <Span>
          <DatoCmsText text={charts[page].description} />
        </Span>
      </S.ChartDescriptionSection>
      <S.ChartContainer>
        <BarChart
          data={charts[page].values}
          yAxisStepSize={charts[page].yAxisStepSize}
        />
        <S.ChartPager>
          {charts.map((d, i) => (
            <button
              key={d.title}
              className={i === page ? "selected" : null}
              onClick={() => setPage(i)}
            />
          ))}
        </S.ChartPager>
      </S.ChartContainer>
    </GridLayout>
  )
}

export default ChartSetSection
