import React, { useState } from "react"
import GridLayout from "../../layouts/GridLayout"
import { useForm } from "react-hook-form"
import * as S from "./ContactForm.styled"
import { P } from "../../components/styles/TextStyles.styled"
import Input from "../../components/Input"
import Checkbox from "../../components/Checkbox"
import { IconButton } from "../../components/styles/Buttons.styled"
import { ReactComponent as Arrow } from "../../assets/arrow-right.svg"
import { graphql, useStaticQuery } from "gatsby"
import submitForm from "../../integration/forms"
import DatoCmsText from "../../components/DatoCmsText/DatoCmsText"
import { Message } from "./ContactForm.styled"

const ContactForm = ({
  locale,
  title,
  submitLabel,
  withSubject,
  withMessage,
  hideMiddleName,
  hideCompany,
  termsAndConditionsLabel,
  formName,
  hiddenFields = [],
  successMessage,
  ...props
}) => {
  const { register, handleSubmit, errors } = useForm()
  const { allDatoCmsTranslation } = useStaticQuery(graphql`
    {
      allDatoCmsTranslation {
        edges {
          node {
            locale
            subject
            name
            middleName
            lastName
            email
            phoneNumber
            companyName
            message
            submit
            fieldIsRequiredError
            formSubmissionError
          }
        }
      }
    }
  `)

  const [submissionState, setSubmissionState] = useState(null)

  const onSubmit = data => {
    setSubmissionState("submitting")
    return submitForm(data)
      .then(() => setSubmissionState("submitted"))
      .catch(() => setSubmissionState("failed"))
  }

  const labels = allDatoCmsTranslation.edges.find(
    edge => edge.node.locale === locale
  ).node

  if (submissionState === "submitted")
    return (
      <GridLayout {...props}>
        <S.Message>
          <DatoCmsText text={successMessage} />
        </S.Message>
      </GridLayout>
    )

  return (
    <GridLayout
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      data-netlify="true"
      name={formName}
      style={{ cursor: submissionState === "submitting" ? "progress" : null }}
      {...props}
    >
      {[
        { name: "form-name", value: formName },
        { name: "locale", value: locale },
        ...(hiddenFields || []),
      ].map(({ name, value }) => (
        <input
          type="hidden"
          key={name}
          name={name}
          value={value}
          ref={register}
        />
      ))}
      <S.Title extraPad={withSubject}>{title}</S.Title>
      {[
        { name: "subject", hidden: !withSubject },
        { name: "name" },
        { name: "middleName", notRequired: true, hidden: hideMiddleName },
        { name: "lastName" },
        { name: "email", type: "email" },
        { name: "phoneNumber", type: "phone" },
        { name: "companyName", hidden: hideCompany },
        {
          name: "message",
          hidden: !withMessage,
          area: true,
        },
      ].map(({ name, type, hidden, area, notRequired }) => {
        if (hidden) return <S.FormField />
        return (
          <S.FormField key={name} threeCol={hideMiddleName}>
            <P as="div">{labels[name]}</P>
            <Input
              altStyle
              name={name}
              type={type || "text"}
              textArea={area}
              ref={register({ required: !notRequired })}
            />
            {errors[name] && <S.Error>{labels.fieldIsRequiredError}</S.Error>}
          </S.FormField>
        )
      })}
      {termsAndConditionsLabel && (
        <S.CheckboxWrapper>
          <Checkbox
            inputRef={register({ required: true })}
            name="acceptsTerms"
            label={termsAndConditionsLabel}
          />
        </S.CheckboxWrapper>
      )}
      {!["submitting", "submitted"].includes(submissionState) && (
        <S.SubmitWrapper onClick={handleSubmit(onSubmit)}>
          <IconButton>
            <Arrow />
          </IconButton>
          {submitLabel || labels.submit}
        </S.SubmitWrapper>
      )}
      {submissionState === "failed" && (
        <Message inside>{labels.formSubmissionError}</Message>
      )}
    </GridLayout>
  )
}

export default ContactForm
