import React from "react"
import GridLayout from "../../layouts/GridLayout"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import {
  Colored,
  H3,
  SubTitle,
} from "../../components/styles/TextStyles.styled"
import DatoCmsText from "../../components/DatoCmsText/DatoCmsText"
import * as S from "./TwoColumns.styled"
import { OthersContainer } from "./TwoColumns.styled"
import TagSet from "../../components/TagSet"
import ArrowedLink from "../../components/Links/ArrowedLink"

const Others = ({ type, items }) => {
  switch (type) {
    case "DatoCmsCallToAction":
      return (
        <div>
          {items.map(it => (
            <ArrowedLink key={it.url} ctaData={it} />
          ))}
        </div>
      )
    case "DatoCmsUnSustainableDevelopmentGoal":
      return (
        <S.GoalsContainer>
          {items.map(({ title, icon }, i) => (
            <S.Goal key={title} index={i}>
              <img src={icon.url} alt={title} />
            </S.Goal>
          ))}
        </S.GoalsContainer>
      )
    case "DatoCmsBrand":
    case "DatoCmsArticleCategory":
      return <TagSet using={items} big />
    default:
      return null
  }
}

export default ({
  title,
  titleLeft,
  titleRight,
  textLeft,
  textStyleLeft = "",
  textLeftNode,
  textRight,
  textRightNode,
  cta,
  othersRight,
  ...props
}) => {
  if (othersRight) {
    othersRight = othersRight.reduce((acc, curr) => {
      const typeArray = acc.find(x => x.type === curr.__typename)
      if (typeArray) {
        typeArray.items.push(curr)
      } else {
        acc.push({
          type: curr.__typename,
          items: [curr],
        })
      }
      return acc
    }, [])
  }
  return (
    <GridLayout {...props}>
      <LinkedSectionTitle title={(cta || !!titleLeft) && title} cta={cta} />
      <S.Left as="div" alternative={textStyleLeft}>
        {(titleLeft && <Colored>{titleLeft}</Colored>) ||
          (!cta && <H3 css={{ margin: 0 }}>{titleLeft || title}</H3>)}
        <DatoCmsText
          as={SubTitle}
          className={`${
            textStyleLeft.toLowerCase().includes("monospaced") && "mono"
          } ${textStyleLeft.toLowerCase().includes("small") && "small"}`}
          text={textLeft}
          textNode={textLeftNode}
        />
      </S.Left>
      <S.Right alternative={textStyleLeft} padTop={!!cta}>
        {titleRight && <SubTitle>{titleRight}</SubTitle>}
        <DatoCmsText
          text={textRight}
          textNode={textRightNode}
          css={{ marginTop: titleRight ? "30px" : null }}
        />
        {othersRight && (
          <OthersContainer alternative={textStyleLeft}>
            {othersRight.map(({ type, items }) => (
              <Others key={type} type={type} items={items} />
            ))}
          </OthersContainer>
        )}
      </S.Right>
    </GridLayout>
  )
}
