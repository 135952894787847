import React from "react"
import GridLayout from "../../layouts/GridLayout"
import { ArticleTitle } from "../../components/styles/TextStyles.styled"
import DatoCmsText from "../../components/DatoCmsText/DatoCmsText"
import styled from "styled-components"
import device from "../../theme/devices"

const Title = styled(ArticleTitle)`
  grid-row: 1;
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-column: 4/10;
  }
`

const Text = styled(DatoCmsText)`
  grid-row: 2;
  grid-column: 1/7;
  @media (${device.desktop}) {
    grid-column: 4 / 11;
  }
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;

  img {
    max-width: 100%;
  }

  a {
    text-decoration: underline;
    color: currentColor;
  }

  table {
    width: 100%;
    background-color: #f3f3f3;
    font-size: 15px;
    margin-top: 28px;
    margin-bottom: 32px;
    td {
      border: 20px solid transparent;
    }
    tr {
      @media (${device.phone}) {
        display: flex;
        flex-direction: column;
      }
    }
    &,
    & * {
      border-color: transparent;
      vertical-align: top;
      tr:first-of-type td > :first-child {
        margin-top: 0;
      }
    }
  }
`

const TextSection = ({ text, title, ...props }) => (
  <GridLayout {...props}>
    {title && <Title>{title}</Title>}
    <Text text={text} />
  </GridLayout>
)

export default TextSection
