import styled, { css } from "styled-components"
import GridLayout from "../../../layouts/GridLayout"
import device from "../../../theme/devices"
import { Span, SubTitle } from "../../../components/styles/TextStyles.styled"

const visibleLeftTransition = css`
  transition: all 250ms ease-out;
  opacity: 0;
  transform: translateX(-50px);

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      transform: none;
    `}
`

const visibleRightTransition = css`
  transition: all 450ms ease-out;
  opacity: 0;
  transform: translateX(350px);

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      transform: none;
    `}
`

export const BrandLogo = styled.div`
  grid-row: 1;
  grid-column: 1 / 3;
  max-width: 100%;

  img {
    max-width: 100%;
  }

  @media (${device.tablet}) {
    grid-column: 1;
  }

  @media (${device.desktop}) {
    grid-column: 2 / 4;
    padding-top: 20px;
  }

  ${visibleLeftTransition}
`

export const Container = styled(GridLayout)`
  overflow-x: hidden;
  max-width: 100%;
  padding-top: 100px;
  & + article {
    padding-top: 40px;
  }
  @media (${device.desktop}) {
    & + article {
      padding-top: 20px;
    }
  }

  transition: all 750ms ease-out;
`

export const Title = styled(SubTitle)`
  grid-row: 2;
  grid-column: 1 / 7;
  display: block;
  ${visibleLeftTransition}

  @media (${device.desktop}) {
    grid-row: 2;
    grid-column: 2 / 6;
    margin-top: 115px;
  }
`

export const Description = styled(Span)`
  grid-row: 4;
  grid-column: 1 / 7;
  ${visibleLeftTransition}

  @media (${device.desktop}) {
    grid-row: 3;
    grid-column: 2 / 6;
    margin-top: 10px;
  }

  span {
    font-weight: 300;
  }
`

export const PageLink = styled.div`
  grid-row: 5;
  grid-column: 1/7;
  ${visibleLeftTransition};
  @media (${device.desktop}) {
    grid-row: 4;
    grid-column: 2 / 6;
    margin-top: 45px;
  }
`

export const WebsiteLink = styled.div`
  ${visibleLeftTransition};
  grid-row: 6;
  grid-column: 1/7;
  @media (${device.desktop}) {
    grid-row: 5;
    grid-column: 2 / 6;
    margin-top: 230px;
    padding-bottom: 35px;
  }

  a {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    color: inherit;
  }
`

export const ImageContainer = styled.div`
  grid-row: 3;
  grid-column: 1 / 7;
  ${visibleRightTransition}

  @media (${device.desktop}) {
    grid-row: 1 / 7;
    grid-column: 7 / 13;
  }
`
