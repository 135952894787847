import styled from "styled-components"
import device from "../../theme/devices"
import ArrowedLink from "../Links/ArrowedLink"
import React from "react"
import { urlFor } from "../../util/content-links"

const LinkSet = styled.div`
  max-width: 650px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transform: translateX(-5px);
  grid-row: 2;
  grid-column: 1 / 7;

  @media (${device.desktop}) {
    grid-column: 2 / 8;
  }

  .link-wrapper {
    background: white;
    padding: 4px 18px 4px 14px;
    margin: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
`

export default ({ using, ...props }) => {
  return (
    <LinkSet {...props}>
      {using.map((l, i) => (
        <ArrowedLink
          key={i}
          link={{ url: urlFor(l.__typename, l.locale, l.slug), label: l.title }}
        />
      ))}
      {props.children}
    </LinkSet>
  )
}
