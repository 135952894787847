import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import GridLayout from "../../layouts/GridLayout"
import GatsbyImageWithIEPolyfill from "gatsby-image/withIEPolyfill"
import device from "../../theme/devices"

const InnerWrapper = styled.div`
  grid-column: 1 / 7;
  display: flex;
  flex-direction: column;
  > img {
    max-width: 100%;
  }
  > :first-child {
    margin-bottom: 20px;
  }

  @media (${device.tablet}) {
    flex-direction: row;
    > * {
      flex: 1;
      margin-bottom: auto !important;
    }
    > :first-child {
      margin-right: 5px;
    }
    > :last-child {
      margin-left: 5px;
    }
  }

  @media (${device.desktop}) {
    grid-column: 4 / 11;
  }
`

export const TwoColumnsImages = ({ images, ...props }) => (
  <GridLayout {...props}>
    <InnerWrapper>
      {images.map(img => (
        <React.Fragment key={img.url}>
          <GatsbyImageWithIEPolyfill fluid={img.fluid} />
        </React.Fragment>
      ))}
    </InnerWrapper>
  </GridLayout>
)

export const fragment = graphql`
  fragment TwoColumnsImagesData on DatoCmsTwoColumnImage {
    id
    __typename
    images {
      url
      alt
      title
      fluid(maxWidth: 720, imgixParams: { auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
      title
    }
  }
`
