import styled from "styled-components"
import device from "../../theme/devices"

export const Header = styled.div`
  position: relative;
`

export const Credits = styled.caption`
  font-size: 9px;
  line-height: 10px;
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-weight: 300;
  color: white;
`

export const HeaderContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 20px;
  padding-top: 90px;
  grid-template-rows: auto 1fr 1fr;

  background: linear-gradient(rgba(0, 0, 0, 0.15) 50%, transparent);

  > div {
    height: 100%;
  }

  .input-wrapper {
    color: white;
  }

  h1 {
    margin-top: 100px;
    grid-column: 1 / 7;
    grid-row: 1;

    @media (${device.desktop}) {
      margin-top: 90px;
      grid-column: 2 / 7;
    }
  }

  form {
    grid-column: 1 / 6;
    max-width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

    input::placeholder {
      color: white;
      opacity: 50%;
    }

    @media (${device.desktop}) {
      grid-column: 2 / 6;
      grid-row: 2;
      width: 100%;
      max-width: 550px;
    }
  }
  > div > .link-wrapper {
    grid-column: 1 / 6;
    grid-row: 3;
    margin-top: auto;
    align-self: flex-start;

    * {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }

    @media (${device.desktop}) {
      grid-column: 2 / 6;
      grid-row: 4;
      align-self: flex-end;
      -ms-grid-row-align: end;
    }
  }

  .survey {
    grid-row: 1 / 5;
    grid-column: 10 / 13;
    max-width: 350px;
    justify-self: flex-end;
    margin-left: auto;
    margin-top: auto;
  }
`
