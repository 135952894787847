import styled from "styled-components"
import { PAsDiv, SubTitle } from "../../components/styles/TextStyles.styled"
import device from "../../theme/devices"

export const Left = styled.div`
  grid-row: 2;
  grid-column: 1 / 7;

  ${SubTitle} {
    &.small {
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
    }
  }

  @media (${device.desktop}) {
    grid-column: 2 / ${props => (props.alternative ? 7 : 8)};
    padding-right: 30px;
  }
`

export const Right = styled(PAsDiv)`
  grid-row: 3;
  grid-column: 1 / 7;
  white-space: pre-line;
  padding-top: ${props => props.padTop && "30px"};

  @media (${device.desktop}) {
    grid-row: 2;
    grid-column: ${props => (props.alternative ? 7 : 8)} / 12;
  }
`
export const OthersContainer = styled.div`
  margin-top: 40px;
  grid-row: 4;
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-row: 3;
    grid-column: ${props => (props.alternative ? 7 : 8)} / 12;
  }
`

export const GoalsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 10px;

  @media (${device.desktop}) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
  }
`

export const Goal = styled.div`
  img {
    width: 100%;
  }

  grid-row: ${props => Math.ceil((props.index + 1) / 7)};
  grid-column: ${props => (props.index + 1) % 7};

  @media (${device.desktop}) {
    grid-row: ${props => Math.ceil((props.index + 1) / 4)};
    grid-column: ${props => (props.index + 1) % 4};
  }
`
