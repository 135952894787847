import styled from "styled-components"
import device from "../../theme/devices"

export const ValueContainer = styled.div`
  grid-row: 2;
  grid-column: 1/7;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;

  @media (${device.tablet}) {
    flex-direction: row;
  }

  @media (${device.desktop}) {
    grid-column: 2 / 12;
    flex-direction: row;
    margin-top: 55px;
  }
`

export const Value = styled.div`
  text-align: center;
  max-width: 340px;
  margin-right: 25px 0;

  span {
    display: block;
    &:first-child {
      font-weight: 300;
      font-size: 60px;
      line-height: 90px;
    }
  }
`
