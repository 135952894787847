import React from "react"
import styled from "styled-components"
import GridLayout from "../../layouts/GridLayout"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import device from "../../theme/devices"
import { SubTitle } from "../../components/styles/TextStyles.styled"
import DatoCmsText from "../../components/DatoCmsText/DatoCmsText"

const Layout = styled(GridLayout)`
  > * {
    color: ${props => props.theme.primaryOverlayTextColor};
  }
  .content {
    white-space: pre-line;
    grid-column: 1 / 7;
    display: block;
  }
  @media (${device.desktop}) {
    > :first-child {
      grid-column: 4 / 8;
    }

    > .cta {
      grid-column: 9 / 11;
    }

    .content {
      grid-row: 2;
      grid-column: 4 / 10;
    }
  }
`

export default ({ cta, title, text, textNode, ...props }) => (
  <Layout {...props}>
    <LinkedSectionTitle cta={cta} title={title} transparentLinkBackground />
    <SubTitle as="div" className="content">
      <DatoCmsText text={text} textNode={textNode} />
    </SubTitle>
  </Layout>
)
