import styled, { css } from "styled-components"
import { H3, Span } from "../styles/TextStyles.styled"

export const Card = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 2px;
  padding: 10px;

  display: flex;
  flex-direction: column;

  ${props => props.withShadow && "box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);"};

  transition: all 250ms ease-out;
  ${props =>
    props.hidden &&
    css`
      opacity: 0;
      transform: translateX(20%);
    `}

  ${props =>
    props.displayNone &&
    css`
      display: none;
    `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  > :first-child {
    padding-top: 15px;
    padding-left: 10px;
    flex: 1;
  }

  > :nth-child(2) {
    cursor: pointer;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  span + h3 {
    margin-top: 0;
  }
`

export const Overline = styled(Span)`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`

export const CardTitle = styled(H3)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`

export const ContentContainer = styled.div`
  padding-left: 10px;
  padding-right: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
`
