import styled, { css } from "styled-components"
import device from "../../theme/devices"
import { P } from "../../components/styles/TextStyles.styled"

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: ${props => (props.extraPad ? "30px" : "10px")};
  grid-row: 5;
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-row: 4;
    grid-column: 4/8;
  }
`

export const FormField = styled.label`
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  display: block;
  ${[1, 2, 3, 4, 5, 6, 7, 8].map(
    i => css`
      :nth-of-type(${i}) {
        grid-row: ${5 + i};
        grid-column: 1 / 7;
      }
    `
  )};

  &:empty {
    margin: 0;
  }

  @media (${device.desktop}) {
    margin-bottom: 20px;
    ${props =>
      (props.threeCol
        ? [
            [1, 1, 4, 10],
            [2, 2, 4, 7],
            [3, 1, 1, 1],
            [4, 2, 7, 10],
            [5, 3, 4, 7],
            [6, 3, 7, 10],
            [7, 4, 4, 10],
            [8, 5, 4, 10],
          ]
        : [
            [1, 1, 4, 11],
            [2, 2, 4, 7],
            [3, 2, 7, 8],
            [4, 2, 8, 11],
            [5, 3, 4, 8],
            [6, 3, 8, 11],
            [7, 4, 4, 7],
            [8, 5, 4, 11],
          ]
      ).map(
        ([i, row, colStart, colEnd]) => css`
          :nth-of-type(${i}) {
            grid-row: ${4 + row};
            grid-column: ${colStart} / ${colEnd};
          }
        `
      )};
  }
`

export const Error = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`

export const CheckboxWrapper = styled.div`
  grid-row: 14;
  grid-column: 1 / 7;
  margin-top: 20px;

  @media (${device.desktop}) {
    grid-row: 10;
    grid-column: 4 / 11;
  }
`

export const SubmitWrapper = styled.div`
  grid-row: 16;
  grid-column: 1 / 7;
  margin-top: 10px;
  margin-bottom: 70px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  cursor: pointer;

  button {
    margin-right: 11px;
    background: black;
    svg path {
      fill: white;
    }
  }

  @media (${device.desktop}) {
    grid-column: 4 / 11;
    grid-row: 12;
  }
`

export const Message = styled(P)`
  grid-row: ${props => (props.inside ? 15 : 1)};
  grid-column: 1 / 7;

  @media (${device.desktop}) {
    grid-row: ${props => (props.inside ? 11 : 1)};
    grid-column: 4 / 11;
  }
`
