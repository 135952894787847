import styled from "styled-components"
import GridLayout from "../../layouts/GridLayout"
import device from "../../theme/devices"

export const AddressContainer = styled(GridLayout)`
  align-items: start;
  @media (${device.desktop}) {
    h3 {
      margin-top: 90px;
    }
  }
`

export const Address = styled.div`
  grid-row: 2;
  grid-column: 1 / 7;
  white-space: pre-wrap;

  @media (${device.desktop}) {
    grid-column: 2 / 6;
  }
`

export const MapContainer = styled.div`
  grid-row: 3;
  grid-column: 1 / 7;

  height: 650px;

  iframe {
    width: 100%;
    height: 100%;
  }
  @media (${device.desktop}) {
    grid-row: 1 / 5;
    grid-column: 7 / 13;
  }
`
