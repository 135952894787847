import React from "react"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import * as S from "./Map.styled"
import { P } from "../../components/styles/TextStyles.styled"
import DatoCmsText from "../../components/DatoCmsText/DatoCmsText"

const MapSection = ({ title, contact }) => (
  <S.AddressContainer>
    <LinkedSectionTitle title={title} />
    <S.Address>
      <P as={"div"}>
        <DatoCmsText as={React.Fragment} text={contact.address} />
        <a href={`mailto:${contact.email}`}>{contact.email}</a>
        <br />
        <a href={`tel:${contact.phone}`}>{contact.phone}</a>
      </P>
    </S.Address>
    <S.MapContainer
      dangerouslySetInnerHTML={{
        __html: contact.mapsIframe,
      }}
    />
  </S.AddressContainer>
)

export default MapSection
