import React, { useContext } from "react"
import HeroSection from "../../sections/generic/Hero"
import ArticleCarouselSection from "../../sections/index/ArticleCarousel"
import CardCarouselSection from "../../sections/generic/CardCarousel"
import ContactForm from "../../sections/generic/ContactForm"
import TextSection from "../../sections/generic/TextSection"
import MapSection from "../../sections/generic/Map"
import ProductThumbnailSection from "../../sections/product-overview/ProductThumbnail"
import TeamMembersSection from "../../sections/about/TeamMembersSection"
import LinkedHero from "../../sections/generic/LinkedHero"
import PrimarySection from "../../sections/generic/PrimarySection"
import TwoColumns from "../../sections/generic/TwoColumns"
import LinkSetSection from "../../sections/index/LinkSetSection"
import NumberSetSection from "../../sections/generic/NumberSet"
import ChartSetSection from "../../sections/index/ChartSet"
import LogoSetSection from "../../sections/generic/LogoSet"
import * as S from "./styled"
import NewsBlock from "../../sections/generic/NewsBlock"
import { WrappedFileDownloadLink } from "../FileDownloadLink"
import { CallToActionBlock } from "../../sections/generic/CallToActionBlock"
import { TwoColumnsImages } from "../../sections/generic/TwoColumnsImages"
import { ThemeContext, ThemeProvider } from "styled-components"

const getBackground = ({ __typename, background }) => {
  return background && background.includes("partially")
    ? "Gray"
    : __typename === "DatoCmsPrimary"
    ? "Yellow"
    : background || "Plain"
}

const Section = ({ background, item, ...props }) => {
  let Result
  const theme = useContext(ThemeContext)
  switch (background) {
    case "Yellow":
      return (
        <ThemeProvider
          theme={{ ...theme, color: theme.primaryOverlayTextColor }}
        >
          <S.Primary {...props} />
        </ThemeProvider>
      )
      break
    case "Gray":
      if (item.background?.includes("partial")) {
        Result = S.PartialGray
      } else Result = S.Gray
      break
    default:
      Result = S.Plain
  }
  return <Result {...props} />
}

const shouldPadTop = (current, previous, nextTypename) => {
  if (
    current.__typename === "DatoCmsCallToActionBlock" &&
    getBackground(current) === getBackground(previous)
  )
    return false
  if (previous.__typename) return true
  return current.__typename !== "DatoCmsHero"
}

const shouldPadBottom = (current, previous, next) => {
  return getBackground(current) !== getBackground(next)
}

const DatoCmsPageContent = ({ previous, next, ...props }) => {
  const { __typename } = props

  props.padTop = shouldPadTop(props, previous, next)
  props.padBottom = shouldPadBottom(props, previous, next)

  switch (__typename) {
    case "DatoCmsTwoColumnImage":
      return <TwoColumnsImages {...props} />
    case "DatoCmsCallToActionBlock":
      return <CallToActionBlock {...props} />
    case "DatoCmsHero":
      return <HeroSection hero={props} />
    case "DatoCmsArticleCarousel":
      return <ArticleCarouselSection {...props} />
    case "DatoCmsCardCarousel":
      return <CardCarouselSection {...props} />
    case "DatoCmsContactForm":
      return <ContactForm {...props} />
    case "DatoCmsText":
      return <TextSection {...props} />
    case "DatoCmsMapSection":
      return <MapSection {...props} />
    case "DatoCmsProductThumbnail":
      return <ProductThumbnailSection {...props} />
    case "DatoCmsTeamMemberCarousel":
      return <TeamMembersSection {...props} />
    case "DatoCmsLinkHero":
      return <LinkedHero {...props} />
    case "DatoCmsPrimary":
      return <PrimarySection {...props} />
    case "DatoCmsTwoColumn":
      return <TwoColumns {...props} />
    case "DatoCmsLinkSet":
      return <LinkSetSection {...props} />
    case "DatoCmsNumberSet":
      return <NumberSetSection {...props} />
    case "DatoCmsChartSet":
      return <ChartSetSection {...props} />
    case "DatoCmsLogoSet":
      return <LogoSetSection {...props} />
    case "DatoCmsNewsBlock":
      return <NewsBlock {...props} />
    case "DatoCmsDownload":
      return <WrappedFileDownloadLink {...props} {...props.file} />
    default:
      return __typename
  }
}

export default ({ content }) => {
  return content?.map((c, i) => (
    <Section key={i} id={c.slug} background={getBackground(c)} item={c}>
      <DatoCmsPageContent
        previous={{
          __typename: i > 0 && content[i - 1].__typename,
          background: i > 0 && content[i - 1].background,
        }}
        next={{
          __typename: i < content.length - 1 && content[i + 1].__typename,
          background: i < content.length - 1 && content[i + 1].background,
        }}
        {...c}
      />
    </Section>
  ))
}
