import React from "react"
import GridLayout from "../../layouts/GridLayout"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import * as S from "./NumberSet.styled"
import { Span } from "../../components/styles/TextStyles.styled"

const NumberSetSection = ({ title, cta, values, ...props }) => (
  <GridLayout {...props}>
    <LinkedSectionTitle cta={cta} title={title} />
    <S.ValueContainer>
      {values.map(({ title, value }) => (
        <S.Value key={title}>
          <span>{value}</span>
          <Span>{title}</Span>
        </S.Value>
      ))}
    </S.ValueContainer>
  </GridLayout>
)

export default NumberSetSection
