import React from "react"
import CarouselSection from "../generic/CarouselSection"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import Carousel from "../../components/Carousel"

export default ({ title, members, ...props }) => (
  <CarouselSection
    {...props}
    carousel={<Carousel type="people" items={members} />}
  >
    <LinkedSectionTitle title={title} />
  </CarouselSection>
)
