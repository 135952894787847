import React, { useEffect, useState } from "react"
import * as S from "./styled"
import { IconButtonWithShadow } from "../styles/Buttons.styled"
import { ReactComponent as X } from "../../assets/x.svg"

const Card = ({
  shadow,
  title,
  overline,
  onClose,
  children,
  hidden,
  ...props
}) => {
  const [displayNone, setDisplayNone] = useState(false)
  useEffect(() => {
    hidden && setTimeout(() => setDisplayNone(true), 300)
  }, [hidden])
  return (
    <S.Card
      {...props}
      hidden={hidden}
      displayNone={displayNone}
      withShadow={shadow}
    >
      <S.Header>
        <S.TitleContainer>
          {overline && <S.Overline>{overline}</S.Overline>}
          {title && <S.CardTitle>{title}</S.CardTitle>}
        </S.TitleContainer>
        {onClose !== undefined && (
          <IconButtonWithShadow onClick={() => onClose()}>
            <X />
          </IconButtonWithShadow>
        )}
      </S.Header>
      <S.ContentContainer>{children}</S.ContentContainer>
    </S.Card>
  )
}

export default Card
