import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageWrapperContext from "../context/PageWrapperContext"
import light from "../theme"
import GridLayout from "../layouts/GridLayout"
import BackButton from "../components/BackButton"
import DatoCmsPageContent from "../components/DatoCmsPageContent"
import dark from "../theme/dark"
import PageTransition from "gatsby-plugin-page-transitions"

export default ({ data: { page } }) => {
  const {
    setHeaderTheme,
    setTheme,
    setDecorated,
    setFixedHeader,
  } = React.useContext(PageWrapperContext)

  const hero = page.content[0]?.__typename === "DatoCmsHero"

  useEffect(() => {
    setDecorated(true)
    setHeaderTheme(hero ? dark : light)
    setFixedHeader(hero)
    setTheme(PageWrapperContext.default.theme)
    return () => setFixedHeader(false)
  })
  return (
    <PageTransition>
      <HelmetDatoCms seo={page.seoMetaTags} />
      {page.backButton && (
        <GridLayout style={{ paddingTop: "50px" }}>
          <BackButton locale={page.locale} onClick={() => navigate(-1)} />
        </GridLayout>
      )}
      <DatoCmsPageContent content={page.content} />
    </PageTransition>
  )
}

export const query = graphql`
  query($id: String!, $locale: String) {
    page: datoCmsPage(locale: { eq: $locale }, id: { eq: $id }) {
      id
      locale
      name
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backButton
      ...DatoCmsPageContent
    }
  }
`
