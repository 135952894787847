import React, { useContext, useState } from "react"
import useMedia from "use-media"
import { size } from "../../theme/devices"
import * as S from "./Hero.styled"
import Img from "gatsby-image/index"
import dark from "../../theme/dark"
import { H1, Span } from "../../components/styles/TextStyles.styled"
import Input from "../../components/Input"
import { ReactComponent as SearchIcon } from "../../assets/ic-search.svg"
import ArrowedLink from "../../components/Links/ArrowedLink"
import Card from "../../components/Card"
import { graphql, useStaticQuery } from "gatsby"
import DatoCmsText from "../../components/DatoCmsText/DatoCmsText"
import SearchContext from "../../context/SearchContext"
import GridLayout from "../../layouts/GridLayout"
import { DomainAwareThemeProvider } from "../../components/DomainAwareThemeProvider"

const HeroSection = ({ hero }) => {
  const isDesktop = useMedia({ minWidth: size.desktop })
  const { allDatoCmsTranslation } = useStaticQuery(graphql`
    {
      allDatoCmsTranslation {
        edges {
          node {
            locale
            searchForSustainableTopics
          }
        }
      }
    }
  `)

  const [hiddenCards, setHiddenCards] = useState(
    typeof sessionStorage === "undefined" ||
      sessionStorage.getItem(`${hero.id}-cards-hidden`)
  )

  const labels = allDatoCmsTranslation.edges.find(
    edge => edge.node.locale === hero.locale
  ).node

  const { search } = useContext(SearchContext)
  return (
    <>
      <S.Header>
        <Img
          fluid={hero.visual.fluid}
          objectFit="cover"
          alt={hero.visual.title}
          style={{ height: "610px" }}
        />

        <S.HeaderContentContainer>
          <GridLayout>
            <DomainAwareThemeProvider theme={dark}>
              <H1>{hero.title}</H1>
              {hero.action === "Search by sustainable topics" ? (
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    return search(labels.locale, undefined, e.target.term.value)
                  }}
                >
                  <Input
                    name="term"
                    prefix={<SearchIcon />}
                    placeholder={labels.searchForSustainableTopics}
                  />
                </form>
              ) : (
                <div />
              )}
              {hero.cta ? <ArrowedLink ctaData={hero.cta} /> : <div />}
            </DomainAwareThemeProvider>
            {isDesktop && hero.card && (
              <Card
                hidden={hiddenCards?.includes(hero.card.id)}
                className={"survey"}
                title={hero.card.title}
                overline={hero.card.overline}
                onClose={() =>
                  setHiddenCards(s => {
                    sessionStorage.setItem(
                      `${hero.id}-cards-hidden`,
                      s + hero.card.id
                    )
                    return s + hero.card.id
                  })
                }
              >
                <Span>
                  <DatoCmsText text={hero.card.description} />
                </Span>
                <ArrowedLink
                  ctaData={hero.card.cta}
                  style={{ marginTop: "65px" }}
                />
              </Card>
            )}
          </GridLayout>
          <S.Credits>{hero.credits}</S.Credits>
        </S.HeaderContentContainer>
      </S.Header>
    </>
  )
}

export default HeroSection
