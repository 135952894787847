import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import ArrowedLink from "../../components/Links/ArrowedLink"
import GridLayout from "../../layouts/GridLayout"
import device from "../../theme/devices"

const Container = styled.div`
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-column: 4 / 11;
  }
`

export const CallToActionBlock = ({ cta, ...props }) => (
  <GridLayout {...props}>
    <Container>
      <ArrowedLink ctaData={cta} />
    </Container>
  </GridLayout>
)

export const fragment = graphql`
  fragment CTABlockData on DatoCmsCallToActionBlock {
    id
    __typename
    cta {
      ...CTA
    }
  }
`
