import React from "react"
import CarouselSection from "./CarouselSection"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import Carousel from "../../components/Carousel"
import styled, { css } from "styled-components"
import { Span } from "../../components/styles/TextStyles.styled"
import device from "../../theme/devices"
import DatoCmsText from "../../components/DatoCmsText/DatoCmsText"

export const Description = styled(Span)`
  margin-bottom: 5px;
  grid-row: 2;
  grid-column: 1 / 7;
  display: block;

  @media (${device.desktop}) {
    ${props =>
      !props.afterLink &&
      css`
        grid-row: 1;
      `};
    grid-column: 8 / 12;
  }
`

const CardCarouselSection = props => (
  <CarouselSection
    {...props}
    gridRow={3}
    gridRowDesktop={3}
    carousel={
      <Carousel
        type="cards"
        hybrid={!props.cards.find(c => c.__typename !== "DatoCmsBrand")}
        items={props.cards}
        forceSameWidthOnAllCards={props.forceSameWidthOnAllCards}
      />
    }
  >
    <LinkedSectionTitle title={props.title} cta={props.cta} />
    {props.description && (
      <Description afterLink={props.cta}>
        <DatoCmsText
          textNode={props.descriptionNode}
          text={props.description}
        />
      </Description>
    )}
  </CarouselSection>
)

export default CardCarouselSection
