import styled from "styled-components"
import device from "../../theme/devices"

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  grid-column: 1 / 7;
  grid-row: 2;
  padding-bottom: 20px;

  & + article {
    grid-row: 3;
  }

  @media (${device.tablet}) {
    grid-column: 1 / 4;
    grid-row: 2;
    padding-bottom: 0;
    & + article {
      grid-column: 4 / 7;
      grid-row: 2;
    }
  }

  @media (${device.desktop}) {
    grid-column: 2 / 6;
    margin-top: 60px;
    & + article {
      grid-column: 8 / 12;
    }
  }

  > :last-child {
  }

  .title {
    min-height: 75px;
    padding-bottom: 10px;
  }

  .description {
    padding-bottom: 25px;
  }
`
