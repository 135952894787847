import React, { useContext } from "react"
import GridLayout from "../../layouts/GridLayout"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import * as S from "./NewsBlock.styled"
import { Span, SubTitle } from "../../components/styles/TextStyles.styled"
import { graphql, useStaticQuery } from "gatsby"
import ArrowedLink from "../../components/Links/ArrowedLink"
import { urlFor } from "../../util/content-links"
import { DomainContext } from "../../context/DomainContext"

const NewsBlock = ({ title, locale, cta, news, ...props }) => {
  const domainContext = useContext(DomainContext)
  const { allDatoCmsTranslation } = useStaticQuery(graphql`
    {
      allDatoCmsTranslation {
        edges {
          node {
            locale
            learnMore
          }
        }
      }
    }
  `)
  const labels = allDatoCmsTranslation.edges.find(
    edge => edge.node.locale === locale
  )
  return (
    <GridLayout {...props}>
      <LinkedSectionTitle title={title} cta={cta} />
      {news.map(n => (
        <S.Card key={JSON.stringify(n)}>
          <SubTitle className="title">{n.title}</SubTitle>
          <Span className="description">{n.seo.description}</Span>
          <ArrowedLink
            link={{
              external: !domainContext.isCurrentDefault,
              label: labels.node.learnMore,
              url: urlFor(n.__typename, locale, n.slug, "", domainContext),
            }}
          />
        </S.Card>
      ))}
    </GridLayout>
  )
}

export default NewsBlock
