import React from "react"
import "./styles.css"
import styled from "styled-components"

const Label = styled.span``

const Checkbox = ({ label, inputRef, labelAs = "span", ...props }) => {
  return (
    <label className="b-contain checkbox-wrapper">
      <Label as={labelAs}>{label}</Label>
      <input ref={inputRef} type="checkbox" {...props} />
      <div className="b-input" />
    </label>
  )
}

export default Checkbox
