import React from "react"
import GridLayout from "../../layouts/GridLayout"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import { Span } from "../../components/styles/TextStyles.styled"
import styled from "styled-components"
import device from "../../theme/devices"
import Img from "gatsby-image/withIEPolyfill"

const Description = styled(Span)`
  grid-row: 2;
  grid-column: 1/7;
  display: block;

  @media (${device.desktop}) {
    grid-column: 2 / 6;
  }
`

const Logos = styled.div`
  grid-row: 3;
  grid-column: 1 / 7;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;

  @media (${device.desktop}) {
    grid-column: 2 / 12;
  }
`

const LogoSetSection = ({ title, description, logos, ...props }) => (
  <GridLayout {...props}>
    {title && <LinkedSectionTitle title={title} />}
    <Description>{description}</Description>
    <Logos>
      {logos.map(logo =>
        logo.fluid ? (
          <Img
            objectFit={"contain"}
            key={logo.alt}
            style={{
              margin: "25px 50px",
              maxHeight: "200px",
              width: logo.width,
            }}
            {...logo}
          />
        ) : null
      )}
    </Logos>
  </GridLayout>
)

export default LogoSetSection
