import styled from "styled-components"
import device from "../../theme/devices"
import { SubTitle } from "../../components/styles/TextStyles.styled"

export const ChartDescriptionSection = styled.div`
  grid-row: 2;
  grid-column: 1 / 7;
  margin-right: 10px;
  display: flex;
  flex-direction: column;

  span:last-child {
    > div {
      min-height: 80px;
    }
    p {
      :first-of-type {
        margin-top: 12px;
      }
    }
  }

  @media (${device.desktop}) {
    grid-row: 1;
    grid-column: 6 / 11;
    margin-right: 0;
  }
`

export const ChartDescriptionTitle = styled(SubTitle)`
  font-weight: 300;
`

export const ChartContainer = styled.div`
  min-height: 400px;
  width: 100%;
  padding-top: 20px;
  grid-row: 3;
  grid-column: 1 / 7;
  position: relative;
  overflow-x: hidden;
  @media (${device.desktop}) {
    min-height: 380px;
    grid-row: 2;
    grid-column: 2 / 13;
  }
`

export const ChartPager = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;

  button {
    width: 11px;
    height: 11px;
    border: 1px solid ${props => props.theme.color};
    box-sizing: border-box;
    display: inline-block;
    border-radius: 50%;
    margin: 0 15px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    padding: 2px;

    outline: none;
    user-select: none;

    &.selected {
      border: 3px solid ${props => props.theme.color};
      box-sizing: border-box;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      cursor: initial;
      padding: 0;
    }
  }
`
