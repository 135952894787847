import styled, { css } from "styled-components"
import device from "../../theme/devices"
import GridLayout from "../../layouts/GridLayout"

export const Container = styled(GridLayout)`
  ${props =>
    props.narrow &&
    css`
      @media (${device.desktop}) {
        h3 {
          grid-column: 4 /8;
        }

        div {
          grid-column: 4 / 10;
        }
      }
    `}
`
