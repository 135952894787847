import React from "react"
import * as S from "./LinkSetSection.styled"
import LinkedSectionTitle from "../../components/SectionTitles/LinkedSectionTitle"
import LinkSet from "../../components/LinkSet/LinkSet"

const LinkSetSection = ({ title, links, ...props }) => {
  return (
    <S.Container {...props}>
      <LinkedSectionTitle title={title} />
      <LinkSet using={links} />
    </S.Container>
  )
}

export default LinkSetSection
