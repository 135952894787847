import styled from "styled-components"

export const Plain = styled.section`
  background: ${props => props.theme.backgroundColor};
`

export const Primary = styled.section`
  background: ${props => props.theme.primaryColor};
  color: ${props => props.theme.color};
`

export const Gray = styled.section`
  background: ${props => props.theme.backgroundColor2};
`

export const PartialGray = styled.section`
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.backgroundColor} 0%,
    ${({ theme }) => theme.backgroundColor} 70%,
    ${({ theme }) => theme.backgroundColor2} 70%
  );
`
