import React from "react"
import * as S from "./styled"
import DatoCmsText from "../../../components/DatoCmsText/DatoCmsText"
import ArrowedLink from "../../../components/Links/ArrowedLink"
import Img from "gatsby-image/withIEPolyfill"
import { urlFor } from "../../../util/content-links"
import { useInView } from "react-intersection-observer"

const ProductThumbnailSection = ({
  __typename,
  pageLinkLabel,
  websiteLink,
  websiteLinkLabel,
  image,
  product: { locale, ...product },
  ...props
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    delay: 1000,
    triggerOnce: true,
  })
  return (
    <S.Container as="article" ref={ref} visible={inView} {...props}>
      <S.BrandLogo visible={inView}>
        <img
          src={product?.logo?.url || product?.parentBrand?.logo?.url}
          alt={product?.title || product?.parentBrand?.title}
        />
      </S.BrandLogo>
      <S.Title visible={inView}>{product.name || product.title}</S.Title>
      {product.description && product.descriptionNode && (
        <S.Description as="div" visible={inView}>
          <DatoCmsText
            text={product.description}
            textNode={product.descriptionNode}
          />
        </S.Description>
      )}
      {product.page && (
        <S.PageLink visible={inView}>
          <ArrowedLink
            link={{
              label: pageLinkLabel,
              url: urlFor("DatoCmsPage", locale, product.page.slug),
              external: false,
            }}
          />
        </S.PageLink>
      )}
      {websiteLink && (
        <S.WebsiteLink visible={inView}>
          <a href={websiteLink} target="_blank" rel="noreferrer">
            {websiteLinkLabel}
          </a>
        </S.WebsiteLink>
      )}
      <S.ImageContainer visible={inView}>
        <Img
          objectFit="cover"
          style={{ height: "100%", width: "100%" }}
          {...image}
        />
      </S.ImageContainer>
    </S.Container>
  )
}

export default ProductThumbnailSection
